import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { CustomBreakPointsProvider } from 'app/shared/layouts/custom-breakpoints';
import { VerticalLayoutModule } from 'app/shared/layouts/vertical/vertical-layout.module';

@NgModule({
  imports: [FlexLayoutModule.withConfig({ disableDefaultBps: true }), VerticalLayoutModule],
  providers: [CustomBreakPointsProvider],
  exports: [VerticalLayoutModule]
})
export class LayoutModule {}
