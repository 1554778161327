import { Component, OnInit, OnDestroy, ViewChild, HostListener, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Subject } from 'rxjs';
import { take, takeUntil, filter } from 'rxjs/operators';


import { CoreConfigService } from '@core/services/config.service';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { DataService } from 'app/shared/services/data.service';
import { AuthenticationService } from 'app/shared/services';
import { environment } from 'environments/environment';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerticalMenuComponent implements OnInit, OnDestroy {
  coreConfig: any;
  menu: any;
  isCollapsed: boolean;
  isScrolled: boolean = false;
  userName: string='';

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {Router} _router
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _coreMenuService: CoreMenuService,
    private _coreSidebarService: CoreSidebarService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private service: DataService,
    private social: SocialAuthService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }


  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Init
   */
  ngOnInit(): void {
    // Subscribe config change
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });

    this.isCollapsed = this._coreSidebarService.getSidebarRegistry('menu').collapsed;

    // Close the menu on router NavigationEnd (Required for small screen to close the menu on select)
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        if (this._coreSidebarService.getSidebarRegistry('menu')) {
          this._coreSidebarService.getSidebarRegistry('menu').close();
        }
      });

    // scroll to active on navigation end
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        setTimeout(() => {
          // this.directiveRef.scrollToElement('.navigation .active', -180, 500);
        });
      });

    // Get current menu
    this._coreMenuService.onMenuChanged
      .pipe(
        filter(value => value !== null),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this.menu = this._coreMenuService.getCurrentMenu();
      });
      console.log('Abhishek=Menu==', this.menu, localStorage.getItem('currentOJCUser'))
      this.getBasicDetails();
      if(localStorage.getItem('currentOJCUser'))
        this.userName = JSON.parse(localStorage.getItem('currentOJCUser')).firstName+" "+JSON.parse(localStorage.getItem('currentOJCUser')).lastName;
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getOrgID(){
    // debugger;
    const currentUser = this._authenticationService.currentUserValue;
    if(currentUser)
    // debugger;
  return currentUser.id;

  }
  taxidvar:any;
  orgdata:any;
  url:any;
  orgname:any;

  getBasicDetails() {
    // debugger;

    let obj = {
      "OrganizationId": this.getOrgID()
    }
    this.service.getBasicDetails(obj).subscribe({
      next: (res: any) => {
        //debugger;
        if(res.success){
          let data=res.d;
          this.taxidvar=res.d.TaxId
          this.orgdata=data;
          if(data.Image){
            this.url=environment.imageUrl+data.Image;
          }
          this.orgname=data.OrganizationName;
          // this.myForm.patchValue({
          //   orgdetail: "static"
          // })
        }
        // debugger;
      },
      error: (res: any) => {
        // debugger;
      }

    })
    
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Sidebar scroll set isScrolled as true
   */
  onSidebarScroll(): void {
    // if (this.directiveRef.position(true).y > 3) {
    //   this.isScrolled = true;
    // } else {
    //   this.isScrolled = false;
    // }
  }

  /**
   * Toggle sidebar expanded status
   */
  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('menu').toggleOpen();
  }

  /**
   * Toggle sidebar collapsed status
   */
  toggleSidebarCollapsible(): void {
    // Get the current menu state
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.isCollapsed = config.layout.menu.collapsed;
      });

    if (this.isCollapsed) {
      this._coreConfigService.setConfig({ layout: { menu: { collapsed: false } } }, { emitEvent: true });
    } else {
      this._coreConfigService.setConfig({ layout: { menu: { collapsed: true } } }, { emitEvent: true });
    }
  }
  logoutFlag = false;
  logoutModel(){
    this.logoutFlag = !this.logoutFlag;
  }
  logout(){
    this._authenticationService.logout();

    this._router.navigate(['/user-account/authentication/login']);
    this.signOut()
  }
  signOut(): void {
    this.social.signOut();
  }

}
