import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { NavbarComponent } from 'app/shared/page-layout-module/navbar/navbar.component';



@NgModule({
  declarations: [
    NavbarComponent,
  ],
  imports: [RouterModule, NgbModule, CoreCommonModule],
  exports: [NavbarComponent]
})
export class NavbarModule {}
