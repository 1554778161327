import { Component, Inject, OnDestroy, OnInit, ElementRef, Renderer2, AfterViewInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
// import { MatStepperModule } from '@angular/material/stepper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
// import * as Waves from 'node-waves';
import { ToastrService } from 'ngx-toastr';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { CoreTranslationService } from '@core/services/translation.service';

import { menu } from 'app/shared/constants/menu';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './shared/services/authentication.service';
import { DataService } from './shared/services/data.service';
// import { Console, debug } from 'console';
import { MatStepper } from '@angular/material/stepper';
// import Stepper from 'bs-stepper';
import { NgxScannerQrcodeComponent, NgxScannerQrcodeService, ScannerQRCodeConfig, ScannerQRCodeResult, ScannerQRCodeSelectedFiles } from 'ngx-scanner-qrcode';
import { Chart } from 'chart.js';
// import { event } from 'jquery';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  coreConfig: any;
  menu: any;
  defaultLanguage: 'en'; // This language will be used as a fallback when a translation isn't found in the current language
  appLanguage: 'en'; // Set application default language i.e fr

  // Private
  private _unsubscribeAll: Subject<any>;
  newdonorform: FormGroup;
  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {Title} _title
   * @param {Renderer2} _renderer
   * @param {ElementRef} _elementRef
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreLoadingScreenService} _coreLoadingScreenService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreTranslationService} _coreTranslationService
   * @param {TranslateService} _translateService
   */

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });

  submittedform: boolean;
  orgid: number;
  loading: boolean;
  Campaign: any;
  Campaigndropdown
  CampaignName
  selectedCampaign = null;
  donorslist: any;
  stepperboolen = false
  NewDonationAgaiondAmpount: FormGroup
  
  paymentType = null;//cash, check, card, pledge
  currentDate = null;
  currentTime = null;
  pledgeMinDueDate;
  pledgeDueDate = null;
  pledgeFollowupDate = null;
  pledgeList = [];
  done() { }
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _title: Title,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    public _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _coreMenuService: CoreMenuService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService, private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private service: DataService,
    private _toastrService: ToastrService,
    private qrcode: NgxScannerQrcodeService
  ) {
    const dtToday = new Date();
    const month = (dtToday.getMonth() + 1).toString().padStart(2, '0');
    const day = (dtToday.getDate()+1).toString().padStart(2, '0');
    const year = dtToday.getFullYear();

    this.currentDate = (dtToday.getDate()).toString().padStart(2, '0')+'/'+month+'/'+year;
    this.pledgeMinDueDate = `${year}-${month}-${day}`;
    // Get the application main menu
    this.menu = menu;
    // this.selectedAmount = 0.0;
    const currentUser = this._authenticationService.currentUserValue;
    if (currentUser) {
      this.orgid = currentUser.id
    } else {
      this.orgid = 0;
    }


    // Register the menu to the menu service
    this._coreMenuService.register('main', this.menu);

    // Set the main menu as our current menu
    this._coreMenuService.setCurrentMenu('main');

    // Add languages to the translation service
    this._translateService.addLangs(['en', 'fr', 'de', 'pt']);

    // This language will be used as a fallback when a translation isn't found in the current language
    this._translateService.setDefaultLang('en');

    // Set the translations for the menu
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.newdonorform = this._formBuilder.group({
      title: ['', [Validators.required]], //title 
      Firstname: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]+$/)]],
      Lastname: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]+$/)]],
      Cellphone: ['', [Validators.pattern(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/), Validators.required]],
      Homephone: ['', [Validators.required, Validators.pattern(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/)]],

      Email: ['', [Validators.required, Validators.pattern(/^\S+@\S+\.\S+$/)]],
      Address: ['', [Validators.required]],

    }
    );



    this.NewDonationAgaiondAmpount = this._formBuilder.group({
      CamapifDropDown: ['', [Validators.required]],
    });



  }
  /**
   * On init
   */
  ngOnInit(): void {
    
    this.Donorlistfordonationpage()
    // Init wave effect (Ripple effect)


    //Waves.init();

    // Subscribe to config changes
    // debugger;
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      // debugger;
      this.coreConfig = config;

      // Set application default language.

      // Change application language? Read the ngxTranslate Fix

      // ? Use app-config.ts file to set default language
      const appLanguage = this.coreConfig.app.appLanguage || 'en';
      this._translateService.use(appLanguage);

      // ? OR
      // ? User the current browser lang if available, if undefined use 'en'
      // const browserLang = this._translateService.getBrowserLang();
      // this._translateService.use(browserLang.match(/en|fr|de|pt/) ? browserLang : 'en');

      /**
       * ! Fix : ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      /**
       *
       * Using different language than the default ('en') one i.e French?
       * In this case, you may find the issue where application is not properly translated when your app is initialized.
       *
       * It's due to ngxTranslate module and below is a fix for that.
       * Eventually we will move to the multi language implementation over to the Angular's core language service.
       *
       **/

      // Set the default language to 'en' and then back to 'fr'.

      setTimeout(() => {
        this._translateService.setDefaultLang('en');
        this._translateService.setDefaultLang(appLanguage);
      });


      /**
       * !Fix: ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      // Layout
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'vertical-layout',
        'vertical-menu-modern',
        'horizontal-layout',
        'horizontal-menu'
      );
      // Add class based on config options
      if (this.coreConfig.layout.type === 'vertical') {
        this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
      } else if (this.coreConfig.layout.type === 'horizontal') {
        this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
      }

      // Navbar
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'navbar-floating',
        'navbar-static',
        'navbar-sticky',
        'navbar-hidden'
      );
      // debugger;

      this.coreConfig.layout.navbar.type = "fixed-top"
      // Add class based on config options
      if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
        this._elementRef.nativeElement.classList.add('navbar-static');
      } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
        this._elementRef.nativeElement.classList.add('navbar-sticky');
      } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
        this._elementRef.nativeElement.classList.add('navbar-floating');
      } else {
        this._elementRef.nativeElement.classList.add('navbar-hidden');
      }

      // Footer
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

      // Add class based on config options
      if (this.coreConfig.layout.footer.type === 'footer-sticky') {
        this._elementRef.nativeElement.classList.add('footer-fixed');
      } else if (this.coreConfig.layout.footer.type === 'footer-static') {
        this._elementRef.nativeElement.classList.add('footer-static');
      } else {
        this._elementRef.nativeElement.classList.add('footer-hidden');
      }

      // Blank layout
      if (
        this.coreConfig.layout.menu.hidden &&
        this.coreConfig.layout.navbar.hidden &&
        this.coreConfig.layout.footer.hidden
      ) {
        this._elementRef.nativeElement.classList.add('blank-page');
        // ! Fix: Transition issue while coming from blank page
        this._renderer.setAttribute(
          this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
          'style',
          'transition:none'
        );
      } else {
        this._elementRef.nativeElement.classList.remove('blank-page');
        // ! Fix: Transition issue while coming from blank page
        setTimeout(() => {
          this._renderer.setAttribute(
            this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
            'style',
            'transition:300ms ease all'
          );
        }, 0);
        // If navbar hidden
        if (this.coreConfig.layout.navbar.hidden) {
          this._elementRef.nativeElement.classList.add('navbar-hidden');
        }
        // Menu (Vertical menu hidden)
        if (this.coreConfig.layout.menu.hidden) {
          this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
        } else {
          this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
        }
        // Footer
        if (this.coreConfig.layout.footer.hidden) {
          this._elementRef.nativeElement.classList.add('footer-hidden');
        }
      }

      // Skin Class (Adding to body as it requires highest priority)
      if (this.coreConfig.layout.skin !== '' && this.coreConfig.layout.skin !== undefined) {
        this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
        this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
      }
      
      
    });

    // Set the application page title
    // debugger;
    //this.coreConfig.app.
    this._title.setTitle("Linx OJC- Web Admin ");
  }
  donorid;
  sortfilters = false;
  paidDonationId = null;
  changeDueAmount: string = "0";
  processing: boolean = false
  AferPyment: boolean = false
  paymentSuccessful: boolean = false;
  paymentFailure: boolean = false;
  receiptScreen: boolean = false;
  GetDate = []
  stepperboolen3 = false;
  newDonationCash() {
    
    if(!this.selectedAmount){
      this.showToast('Please enter valid amount', 'error');
      return;
    }
    this.cashdiv = false;
    this.checkdiv = false;
    let obj = {
      "PledgeId": this.selectedPledge,
      "DonationId": 0,
      "DonorId": this.SelectedItem,
      "CampaignId": this.Campaigndropdown,
      "OrganizationId": this.orgid,
      "Amount": this.selectedAmount,
      "ChangeDue": this.changeDueAmount,
      "PaymentMethod": "Cash",
      "DonationStatus": "Approved"
    }
 
    this.loading = true
    this.processing = true
    this.stepperboolen3 = true;

    this.service.AddDonation(obj).subscribe((res) => {
      
      if (res.success) {
        this.paidDonationId = res.donationId;
        this.processing = false
        this.AferPyment = true
        this.paymentSuccessful = true;
        this.paymentFailure = false;

        this.showToast(res.message, 'success');
      }
      else {
        this.loading = false;
        this.processing = false;
        this.AferPyment = true;
        this.paymentSuccessful = false;
        this.paymentFailure = true;
        this.showToast('Error!', 'error');
      }
    },
      (error) => {
        this.loading = false;
        this.processing = false;
        this.AferPyment = true;
        this.paymentSuccessful = false;
        this.paymentFailure = true;
        this.showToast('Error!', 'error');
      }
    )
    console.log(obj)
  }

  newDonationCheck() {
    if(!this.checkURL){
      this.showToast('Please upload check image', 'error');
    }
    else if(!this.checkNumber){
      this.showToast('Please enter check number', 'error');
    }
    else{
      this.cashdiv = false;
      this.checkdiv = false;
      this.loading = true
      this.processing = true
      this.stepperboolen3 = true;

      let obj = {
        "PledgeId": this.selectedPledge,
        "DonorId": this.SelectedItem,
        "CampaignId": this.Campaigndropdown,
        "OrganizationId": this.orgid,
        "Amount": this.selectedAmount,
        "PaymentMethod": "Check",
        "CheckNumber": this.checkNumber,
        "CheckImageLocation": this.checkURL
      }
      this.service.AddCheckDonation(obj).subscribe(
        (res) => {
          if (res.success) {
            this.paidDonationId = res.donationId;
            this.processing = false
            this.AferPyment = true
            this.paymentSuccessful = true;
            this.paymentFailure = false;
            this.showToast(res.message, 'success');
          }
          else {
            this.loading = false;
            this.processing = false;
            this.AferPyment = true;
            this.paymentSuccessful = false;
            this.paymentFailure = true;
            this.showToast("Error!", 'error');
          }
        },
        (error) => {
          this.loading = false;
          this.processing = false;
          this.AferPyment = true;
          this.paymentSuccessful = false;
          this.paymentFailure = true;
          this.showToast("Error!", 'error');
          console.log(error)
          /*******temp******** */
          setTimeout(() => {
            this.processing = false;
            this.AferPyment = true;
            this.paymentSuccessful = true;
            this.paymentFailure = false;
          }, 5000);
          /*************** */
        }
      )
    }
  }
  
  receiptChart: Chart = null;
  smsActive: boolean = false;
  messageActive: boolean = false;
  addNewEmail:boolean = false;
  addNewSMS:boolean = false;
  receiptClicked(){
    //$('#mailDetailBox').hide();
    //$('#smsDetailBox').hide();
    this.AferPyment = false;
    this.paymentSuccessful = false;
    this.paymentFailure = false;
    this.receiptScreen = true;

    let d1 = (30/70)*100;
    let d2 = 100 - d1;
    var tempObj:any = {
      type: 'pie', //this denotes tha type of chart
      data: {// values on X-Axis
        datasets: [{
          data: [d1,d2],
          backgroundColor: [
            'red',
            'white'			
          ],
          borderColor: '#DCDFE4',
          borderWidth: 0.5,
        }],
      },
      options: {
        aspectRatio:2.1,
        maintainAspectRatio: false,
        events: []
      }
    }
    setTimeout(() => {
      this.receiptChart = new Chart('MyReceiptChart', tempObj);  
    }, 500);
    
  }

  messageClicked(){
    this.smsActive = false;
    this.messageActive = !this.messageActive;
    console.log('message clicked', this.messageActive);
    //$('#mailDetailBox').toggle();
    //$('#smsDetailBox').hide();
  }
  addNewEmailChange(evt){
    this.addNewEmail = evt.target.checked;
  }
  addNewSMSChange(evt){
    this.addNewSMS = evt.target.checked;
  }
  smsClicked(){
    this.messageActive = false;
    this.smsActive = !this.smsActive;
    console.log('sms clicked', this.smsActive)
    //$('#mailDetailBox').hide();
    //$('#smsDetailBox').toggle();
  }
  emailList = ['jack@gmail.com', 'jake@company.com'];
  smsList = ['516-908-9654', '516-908-9654'];
  sendEmailSms(stepper){
    if(this.messageActive){
      let mailArr = [];
      this.document.getElementsByClassName('mailSent')[0].checked && mailArr.push(this.emailList[0]);
      this.document.getElementsByClassName('mailSent')[1].checked && mailArr.push(this.emailList[1]);
      this.document.getElementsByClassName('mailSent')[2].checked && mailArr.push($('.customMail').val());
      let obj = {
        "DonationId":this.paidDonationId,
        "Emails":mailArr
        }
      this.service.sendEmail(obj).subscribe(
        dta=>{ 
          this.showToast('Email sent', 'success');
          this.closeDonationPop(stepper);
        },
        error=>{
          this.showToast('Server error!', 'error');
          this.closeDonationPop(stepper);
        }
      );
    }
  }

  printReciept(stepper){
    let obj = {
      "DonationId":this.paidDonationId
    }
    this.service.printReciept(obj).subscribe(
      dta=>{
        if(dta.success){
          window.open(dta.filePath, "_blank")
          this.showToast('Reciept downloaded successfully.', 'success');
          this.closeDonationPop(stepper);
        }
      },
      error=>{
        this.showToast('Server error!', 'error');
        this.closeDonationPop(stepper);
      }
    );
  }
  
  TransformValue(value) {
    if (isNaN(value) || value === null) {
      return '';
    }
    return '$' + value.toFixed(2);
  }



  newdonorformSubmit() {
    this.submittedform = true;
    if (this.newdonorform.invalid) {
      return;
    }
    let obj = {
      "OrganizationId": this.orgid,
      "Title": this.newdonorform.controls.title.value.$ngOptionLabel,
      "DonorName": this.newdonorform.controls.Firstname.value,
      "DonorNameHe": this.newdonorform.controls.Lastname.value,
      "PrimaryContact": this.newdonorform.controls.Cellphone.value,
      "PrimaryEmail": this.newdonorform.controls.Email.value,
      "Address": this.newdonorform.controls.Address.value,
    }
    console.log("orgid", this.orgid,);

    this.loading = true
    this.service.adddonorfromdonation(obj).subscribe((res) => {
      //debugger;
      this.loading = false;
      if (res.success) {
        //debugger;
        this.loading = false;
        // alert("fdfdf")
        // this.donor =res.DonorId;
        this.newdonorform.reset();
        this.submittedform = false
        this.donoradd = false;
        this.Donorlistfordonationpage();
        
        $("#CloseButtonAdd").click();
        this._toastrService.success(
          res.message,
          '',
          { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 5000, disableTimeOut: false }
        );
      }
      else {
        this.loading = false
        this._toastrService.error(
          "Error!",
          '',
          { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 5000, disableTimeOut: false }
        );
      }
    },
      (error) => {

        console.log(error)
      }
    )
  }
  CampaignListAPi() {
    let obj = {
      "OrganizationId": this.orgid,
      "Paging": {
        "PageSize": 1000,
        "PageIndex": 1
      }
    }

    //this.service.GetList(obj).subscribe((res) => {
    this.service.getTopCampaignList(obj).subscribe((res) => {
      this.Campaign = [];
      let tempArr = res.d.Campaigns;
      tempArr.forEach(data => {
        data.DaysLeft>=0 && this.Campaign.push(data);
      });
    },
      (error) => {
        console.log(error)
      }
    )
  }
  getChart = []
  Donorlistfordonationpage() {
    let obj = {
      "OrganizationId": this.orgid,
      //"ColumnName": "",
      //"OrderBy": "Asc", // Asc/Dsc
      //"Group": "", //....
      //"DonorName": "",
      //"State": "",
      //"City": "",
      //"Zip": ""
    }

    //this.service.donorlistfordononation(obj).subscribe((res) => {
    this.service.GetDonorList(obj).subscribe((res) => {
      console.log('Abhishek=Donor===', res)
      this.donorslist = res.d.Donors;
      //this.getChart=[...this.donorslist]
      this.donorslist.forEach(dta=>{
        dta.DOB = dta.DOB.split('T')[0].split('-').reverse().join('/');
        this.getChart.push(dta);
      });
    },
      (error) => {
        console.log(error)
      }

    )
  }

  rows2 = [
    { DonorId:'d1', DonorName: 'Mr1 Elmer processed', DonorAddress: 'Brooklyn NY 11345', PrimaryContact: '0987654321', WorkPhone: '1234567890', PrimaryEmail: 'rocky1@gmail.com' },
    { DonorId:'d2', DonorName: 'Mr2 Elmer processed', DonorAddress: 'Brooklyn NY 11345',PrimaryContact: '0987654321', WorkPhone: '1234567890', PrimaryEmail: 'rocky2@gmail.com' },
    { DonorId:'d3', DonorName: 'Mr3 Elmer processed', DonorAddress: 'Brooklyn NY 11345',PrimaryContact: '0987654321', WorkPhone: '1234567890', PrimaryEmail: 'rocky3@gmail.com' },
    { DonorId:'d4', DonorName: 'Mr4 Elmer processed', DonorAddress: 'Brooklyn NY 11345',PrimaryContact: '0987654321', WorkPhone: '1234567890', PrimaryEmail: 'rocky4@gmail.com' },
    { DonorId:'d5', DonorName: 'Mr5 Elmer processed', DonorAddress: 'Brooklyn NY 11345',PrimaryContact: '0987654321', WorkPhone: '1234567890', PrimaryEmail: 'rocky5@gmail.com' },
    { DonorId:'d6', DonorName: 'Mr6 Elmer processed', DonorAddress: 'Brooklyn NY 11345',PrimaryContact: '0987654321', WorkPhone: '1234567890', PrimaryEmail: 'rocky6@gmail.com' },
  ];

  searchTerm = '';
  filterUpdate() {
    //debugger;
    if (this.searchTerm.trim() === '') {
      // If search term is empty, reset to the original getChart data
      this.getChart = [...this.donorslist];
    } else {
      // Filter the chart data based on the search term
      const filteredChart = this.donorslist.filter(chartData =>
        chartData.DonorName.toLowerCase().includes(this.searchTerm.toLowerCase())
      );

      // Update the chart data to display only the filtered results
      this.getChart = filteredChart;
    }
  }



  formatPhoneNumber(phoneNumberString: string): string {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
  }
  onPhoneNumberChange(event: any, controlName: string): void {
    const inputValue = event.target.value;
    let formattedValue = '';

    if (controlName === 'Cellphone') {
      formattedValue = this.formatPhoneNumber(inputValue);
    } else if (controlName === 'Homephone') {
      formattedValue = this.formatPhoneNumber(inputValue);
    } else if (controlName === 'Workphone') {
      formattedValue = this.formatPhoneNumber(inputValue);
    }

    this.newdonorform.get(controlName).setValue(formattedValue, { emitEvent: false });
  }


  getCampaignName(value) {
    this.Campaigndropdown = value;
    const selectedCamp = this.Campaign.find(donor => donor.CampaignId === this.Campaigndropdown);
    if (selectedCamp) {
      this.CampaignName = selectedCamp.Name;
      this.selectedCampaign = selectedCamp;
    } else {
      console.log("error")
    }
  }
  SelectedItem: any
  donorname

  SelectDonorlist(value) {
    //debugger;
    this.SelectedItem = value.DonorId;
    this.donorid = value.DonorId;
    this.donorname = value.DonorName
  }

  getCamapignDropdownName: any;

  // slectDropdownNadGetValue(fdf) {
  // //debugger
  //   // this.getCamapignDropdownName = event;
  //   // console.log(this.getCamapignDropdownName); 
  // }
 
  goForward(stepper: MatStepper) {
  
    if (this.SelectedItem == '' || this.SelectedItem == undefined) {
      this._toastrService.error(
        "Please select at least one donor",
        '',
        { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 5000, disableTimeOut: false }
      );
      return
    }
    {
      this.CampaignListAPi();
      this.donationamount = true;
      this.schedulePledge = false;
      stepper.next();
    }
  }
  
  selectedAmount: string;
  selectedPledge: string;
  checkNumber: string;


  setAmount(amount: string): void {
    this.selectedAmount = amount;
  }
  stepperboolen2 = false;
  stepperboolen4 = true;
  stepperboolen5 = true;
  // NewDonation:FormGroup
  goForwardOn(stepper: MatStepper) {
    console.log(this.Campaigndropdown, this.selectedAmount, parseInt(this.selectedAmount))
    if (this.Campaigndropdown == null || this.Campaigndropdown == undefined) {
      this._toastrService.error(
        "Please select campaign Name",
        '',
        { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 5000, disableTimeOut: false }
      );
      return
    }
    else if (!this.selectedAmount || parseInt(this.selectedAmount) <= 0) {
      this._toastrService.error(
        "Please enter at least $1",
        '',
        { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 5000, disableTimeOut: false }
      );
      return
    }
    {
      this.CloseAllDenationScreen();
      this.selectedPledge = null;
      this.stepperboolen2 = true;
      this.donationtypes = true
      stepper.next();
    }
    //this.receiptClicked();
  }




  donateFunc() {
    this.paymentMethod = true
    this.donationtypes = false
    this.AferPyment = false
  }

  Inintial(stepper: MatStepper) {
  }
  
  resetfunc(stepper: MatStepper) {
    
    this.donorsearch = true;
    this.Campaigndropdown = null;
    this.selectedAmount = null
    this.checkNumber = null;
    this.donoradd = false;
    this.stepperboolen = false;
    this.stepperboolen2 = false;
    this.stepperboolen3 = false;
    this.CloseAllDenationScreen();
    stepper.previous();
    stepper.previous();
    stepper.previous();
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  pattern=/^[\w]*$/;
  alphaNumericOnly(event): boolean {
    console.log(event.target.value)
    const charCode = event.target.value;
    if (this.pattern.test(charCode)) {
      return true;
    }
    else{
      this.checkNumber = this.checkNumber.substring(0, this.checkNumber.length - 1);
      return false;
    }
  }

  onAmountChange(amount: string): void {
    const formattedAmount = parseFloat(amount).toFixed(2);
    this.selectedAmount = `$${formattedAmount}`;
  }


  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  thirdFormGroup = this._formBuilder.group({
    thirdCtrl: ['', Validators.required],
  });
  fourthFormGroup = this._formBuilder.group({
    fourthCtrl: ['', Validators.required],
  });
  isLinear = false;
  noresult: any = false;
  donorsearch: any = true;
  donoradd: any = false;
  secondmanual:any = false;
  scancertificate: any = false;
  donationamount: boolean = true;
  donationtypes: any = false;
  repeatediv: boolean = false;
  listtick: boolean = true;
  comtick: boolean = false;
  schedulePledge: boolean = false;
  paymentMethod: any = true;
  cashdiv: boolean = false;
  cardDetailDiv: boolean = false;
  cardEditDiv: boolean = false;
  crdEdit: boolean = false;
  cardAddDiv: boolean = false;
  checkdiv: boolean = false;
  cardSelected: number = null;
  scancertificatediv: any = false;
  followpledgediv: boolean = false;
  showScannerManual: boolean = false;
  manualcertificatentry:any = false;
  paymentmethodform:any = false;
  paymentaccount:any = false;
  paymentcheck:any=false;
  changeaddress:any = false;
  walets:any = false;
  successreports:any = false;
  totalreports:any = false;
  methodpayment:any = true;
  modalstart:any = true;
  ojctype:any = true;
  followpledgefun() {
    this.followpledgediv = true;
    this.paymentMethod = false;
    this.paymentType = 'pledge';
  }

  scancertfun(stepper) {
    this.scancertificate = true;
    this.showScanner = true;
    this.showScannerManual = false;
    this.donorsearch = false;
    stepper.previous();
    stepper.previous();
  }
  cashfun(selectobject) {
    this.paymentMethod = false;
    this.cashdiv = true;
    this.paymentType = 'cash';

  }
  cardfun() {
    this.cardDetailDiv = true;
    this.paymentMethod = false;
    this.crdDetail.forEach((crd, i) => {
      let elem = (<HTMLInputElement>document.getElementsByClassName('creditCrd-list-col')[i])
      elem && (elem.style.border = "0px solid");
    });
    this.paymentType = 'card';
  }
  checkfun() {
    this.checkdiv = true;
    this.paymentMethod = false;
    this.cardDetailDiv = false;
    this.cashdiv = false;
    this.paymentType = 'check';
  }
  schedulePledgeFun() {
    
    if (this.Campaigndropdown == null || this.Campaigndropdown == undefined) {
      this._toastrService.error(
        "Please select campaign Name",
        '',
        { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 5000, disableTimeOut: false }
      );
      return
    }
    else{
      this.CloseAllDenationScreen();
      this.schedulePledge = true;
    }
  }
  donfun() {
    this.listtick = true;
    this.comtick = false;
    (<HTMLInputElement>document.getElementsByClassName('tab-paneWeek')[0]).classList.add("active");
    (<HTMLInputElement>document.getElementsByClassName('tab-paneMonth')[0]).classList.remove("active");
  }
  donfun2() {
    this.comtick = true;
    this.listtick = false;
    (<HTMLInputElement>document.getElementsByClassName('tab-paneWeek')[0]).classList.remove("active");
    (<HTMLInputElement>document.getElementsByClassName('tab-paneMonth')[0]).classList.add("active");
  }
  /*repeatefun() {
    this.repeatediv = true;
    this.donationamount = false;
    this.donationtypes = false;
    this.schedulePledge = false;
  }
  donatenowfun() {
    this.donationamount = false;
    this.donationtypes = true;
    this.repeatediv = false;
  }*/
  adddonorfun() {
    this.donorsearch = false;
    this.donoradd = true;
    this.scancertificate = false;
    this.newdonorform.reset();
    this.newdonorform.controls['title'].setValue("");

  }
  // custom funtion by developer 
  manualcertificate(){
    this.manualcertificatentry = true;
    this.secondmanual = false;
  }
  submitformpayment(){
    this.paymentmethodform = true;
    this.secondmanual = false;
    this.manualcertificatentry = false;
  }
  gotopayment(){
    this.paymentmethodform = false;
    this.paymentaccount = true;
  }
  gotopaymentcheck(){
    this.paymentmethodform = false;
    this.paymentcheck = true;
  }
  changeaddresscheck(){
    this.paymentcheck = false;
    this.changeaddress = true;
  }
  walletsfun(){
    this.changeaddress = false;
    this.walets = true;
    this.paymentaccount = false;
    this.paymentcheck = false;
  }
  submitsuccess(){
    this.walets = false;
    this.successreports = true;
  }
  viewreports(){
    this.successreports = false;
    this.totalreports=true;
  }
  opencertificate(){
    this.ojctype = false;
    this.secondmanual = true;
  }
  backbttonss(){
    this.ojctype = true;
    if(this.secondmanual ){
      this.ojctype = true;
      this.secondmanual = false;
    }
    else if(this.paymentmethodform){
      this.secondmanual = true;
      this.ojctype = false;
      this.paymentmethodform = false;
    }
    else if(this.paymentaccount){
      this.paymentmethodform = true;
      this.ojctype = false;
      this.paymentaccount = false;
    }
    else if(this.paymentcheck){
      this.paymentmethodform = true;
      this.ojctype = false;
      this.paymentcheck = false;
    }
    else if(this.manualcertificatentry){
      this.secondmanual = true;
      this.ojctype = false;
      this.manualcertificatentry = false;
    }
    else if(this.walets){
      this.paymentmethodform = true;
      this.ojctype = false;
      this.walets = false;
    }
    else if(this.successreports){
      this.walets = true;
      this.ojctype = false;
      this.successreports = false;
    }
    else if(this.changeaddress){
      this.paymentcheck = true;
      this.ojctype = false;
      this.changeaddress = false;
    }
    else if(this.totalreports){
      this.successreports = true;
      this.ojctype = false;
      this.totalreports = false;
    }
    }
    @ViewChild('webcamVideo') webcamVideo: ElementRef<HTMLVideoElement>;

    openWebcam() {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          this.webcamVideo.nativeElement.srcObject = stream;
          this.webcamVideo.nativeElement.play();
        })
        .catch((error) => {
          if (error.name === 'NotFoundError') {
           alert( error);
          } else if (error.name === 'NotAllowedError') {
            alert( error);
          } else {
            alert(error);
          }
        });
    }
  
  backbtton(stepper: MatStepper) {
    
    console.log("Current State:1:", stepper.selectedIndex, this.stepperboolen)
    console.log("Current State:2:", stepper, this.stepperboolen2)
    console.log("Current State:3:", stepper, this.stepperboolen3)
    stepper.previous()
    //stepper.selectedIndex=2;
    
    this.stepperboolen = true;
    if(this.donoradd){
      this.donorsearch = true;
      this.donoradd = false;
    }
    if(this.showScanner){
      this.donorsearch = true;
      this.showScanner = false;
    }
    else if(this.scancertificate == true){
      this.donorsearch = true;
      this.donoradd = false;
      this.scancertificate = false;
    }
    else if(this.schedulePledge){
      this.donationamount = true;
      this.schedulePledge = false;
    }
    else if(this.donationamount){
      this.donationamount = false;
      this.schedulePledge = false;
      stepper.previous();
    }
    else if(this.donationtypes)
    {
      this.donationtypes = false;
      this.paymentMethod = false;
      this.donationamount = true;
      stepper.previous();
    }else if(this.paymentMethod){
      this.donationtypes = true;
      this.paymentMethod = false;
    }
    else if(this.repeatediv || this.scancertificatediv || this.followpledgediv){
      this.cashdiv = false;
      this.repeatediv = false;
      this.cardDetailDiv = false;
      this.cardEditDiv = false;
      this.cardAddDiv = false;
      this.checkdiv = false;
      this.scancertificatediv = false;
      this.followpledgediv = false;
      this.donationtypes = true;
      this.paymentMethod = false;
    }
    else if(this.cashdiv || this.cardDetailDiv || this.cardEditDiv || this.cardAddDiv || this.checkdiv){
      this.cashdiv = false;
      this.cardDetailDiv = false;
      this.cardEditDiv = false;
      this.cardAddDiv = false;
      this.checkdiv = false;
      this.paymentMethod = true;
    }
    else if(this.AferPyment || this.receiptScreen){
      this.selectedAmount = '';
      this.Campaigndropdown = null;
      this.donorsearch = true;
      this.donoradd = false;
      this.AferPyment = false;
      this.receiptScreen = false;
      stepper.previous();
      stepper.previous();
    }
  }
  
  public selectRepeateWeeks: any = [];

  public selectRepeateWeekDays:any = [
    { name: 'Monday', value: '1' },
    { name: 'Tuesday', value: '2' },
    { name: 'Wednesday', value: '3' },
    { name: 'Thrusday', value: '4' },
    { name: 'Friday', value: '5' },
    { name: 'Saturday', value: '6' },
    { name: 'Sunday', value: '7' },
  ]

  public selectRepeateMonths: any = [];
  public dayOfEachMonth = 31;
  public monthDetail: any = [
    { name: 'January', value: "January", days: 31},
    { name: 'February', value: "February", days: 28 },
    { name: 'March', value: "March", days: 31 },
    { name: 'April', value: "April", days: 30 },
    { name: 'May', value: "May", days: 31 },
    { name: 'June', value: "June", days: 30 },
    { name: 'July.', value: 'July', days: 31 },
    { name: 'August', value: 'August', days: 31 },
    { name: 'September', value: 'September', days: 30 },
    { name: 'October', value: 'October', days: 31 },
    { name: 'November', value: 'November', days: 30 },
    { name: 'December', value: 'December', days: 31 }
  ];
  public selectRepeateDay: any = [];

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  addNewCrdClick(){
    this.CloseAllDenationScreen();
    this.cardAddDiv = true;
  }
  //temp card detail
  crdDetail = [
    { cardNumber: '2112111111114321', cardName: 'abc1', expDate: '21/2/2030' , status: false },//master
    { cardNumber: '5112111111110987', cardName: 'abc2', expDate: '21/2/2030', status: true },//master
    { cardNumber: '4112111111119876', cardName: 'abc3', expDate: '21/2/2030', status: true },//Visa
    { cardNumber: '3112111111118765', cardName: 'abc4', expDate: '21/2/2030', status: false },//American
  ]

  cardClicked(indx, evt){
    console.log(indx);
    this.crdDetail.forEach((crd, i) => {
      (<HTMLInputElement>document.getElementsByClassName('creditCrd-list-col')[i]).style.border = "0px solid";
    });
    
    (<HTMLInputElement>document.getElementsByClassName('creditCrd-list-col')[indx]).style.border = "2px solid #deb887";
  }
  daysInMonth(month, year) {
    const daysInMonths = [31, (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    return daysInMonths[month - 1];
  }
  openRepeateScreen(){
    this.selectRepeateWeeks.length = 0;
    var totWeek: number = Math.round(this.selectedCampaign.DaysLeft/7);
    for(let i=0; i<totWeek; i++){
      !i && (this.selectRepeateWeeks.push({name:(i+1)+ ' week', value: (i+1)}));
      i && (this.selectRepeateWeeks.push({name:(i+1)+ ' weeks', value: (i+1)}));
    }
    //---------------------
    this.selectRepeateMonths.length = 0;
    var daysLeft = 365;//this.selectedCampaign.DaysLeft;
    const dtToday = new Date();
    let month = parseInt((dtToday.getMonth() + 1).toString().padStart(2, '0'));
    let year = dtToday.getFullYear();
    let count = 0;
    while(daysLeft>0){
      console.log(month, year, this.daysInMonth(month, year));
      let days = this.daysInMonth(month, year);
      if(days<this.dayOfEachMonth)
        this.dayOfEachMonth = days;
      if(daysLeft>days)
        daysLeft = daysLeft - days;
      else{
        days = daysLeft;
        daysLeft = 0;
      }
      count += 1;
      this.selectRepeateMonths.push({name: count, value: count, numDays: days });

      if(month==12){
        month=0;
        year += 1;
      }
      month += 1;
    }
    this.repeateType = 'monthly';
    this.numberOfRepeate = null;
    this.dayOfRepeate = null;
    this.selectedRepeatDate = null;
  }

  repeateType = 'monthly';
  numberOfRepeate = null;
  dayOfRepeate = null;
  selectedRepeatDate = null;
  repeateTypeChange(type){
    this.repeateType = type;
    this.numberOfRepeate = null;
    this.dayOfRepeate = null;
    this.selectedRepeatDate = null;
  }
  repeatDateChange(evt){
    this.selectedRepeatDate = evt.target.value;
    let campEndDate = this.selectedCampaign.EndDate;
    console.log(this.selectedRepeatDate, this.pledgeMinDueDate, campEndDate)

    if(parseInt(this.pledgeMinDueDate.replaceAll('-',''))<=parseInt((this.selectedRepeatDate).replaceAll('-','')) && parseInt((this.selectedRepeatDate).replaceAll('-','')) <= parseInt(campEndDate.replaceAll('-',''))){
      
    }else if(parseInt(this.pledgeMinDueDate.replaceAll('-',''))<=parseInt((this.selectedRepeatDate).replaceAll('-',''))){
      this.showToast('Selected date should be greater then current date', 'error');
    }
    else if(parseInt((this.selectedRepeatDate).replaceAll('-','')) <= parseInt(campEndDate.replaceAll('-',''))){
      this.showToast('Selected date should be less then or equal to campaign end date i.e'+ this.selectedCampaign.EndDate+' .', 'error');
    }
  }
  monthlyRepeateChange(evt){
    this.dayOfRepeate = {'value': parseInt(evt)+1};
  }
  setRepeatDonation(stepper){
    if(!this.numberOfRepeate || !this.dayOfRepeate || !this.selectedRepeatDate){
      this.showToast('Fill all the required fields', 'error');
    }
    else{
      let obj = {
        "donorId": this.donorid,
        "campaignId": this.selectedCampaign.CampaignId,
        "organizationId": this.orgid,
        "amount": ""+this.selectedAmount,
        "repeatType": this.repeateType,
        "numberOf": this.numberOfRepeate?parseInt(this.numberOfRepeate.value):0,
        "dayOf": this.dayOfRepeate?parseInt(this.dayOfRepeate.value):0,
        "startDate": this.selectedRepeatDate
      }
      this.service.setRecurringDonation(obj).subscribe(dta=>{
        this.showToast('Recurring set successfully', 'success');
        this.closeDonationPop(stepper);
      },
    (error)=>{
      this.showToast('Inter server error', 'error');
    });
    }
  }

  public checkImg: File = null;
  checkURL: any = null;
  onSelectCheckFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/gif'];
    
    if (event.target.files && event.target.files[0] && allowedMimeTypes.includes(file.type)) {
      this.checkImg = file;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.checkURL = ''+event.target.result;
        console.log(this.checkURL);
      };
    }else{
      this.showToast('Invalid file format', 'error');
    }
  }

  CloseAllDenationScreen(){
    this.searchTerm = '';
    this.filterUpdate();
    this.processing = false
    this.AferPyment = false
    this.paymentSuccessful = false;
    this.paymentFailure = false;
    this.receiptScreen = false;

    this.donationtypes = false
    this.paymentMethod = false
    this.cashdiv = false
    this.cardDetailDiv = false;
    this.cardEditDiv = false;
    this.cardAddDiv = false;
    this.checkdiv = false;
    this.AferPyment = false

    this.smsActive = false;
    this.messageActive = false;

    this.followpledgediv = false;
    this.repeatediv = false;

    this.schedulePledge = false;
    this.donationamount = false;
  }
  showToast(msg, type){
    if(type=='success'){
      this._toastrService.success(
        msg,
        '',
        { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 5000, disableTimeOut: false }
      );
    }
    else{
      this._toastrService.error(
        msg,
        '',
        { toastClass: 'toast ngx-toastr', closeButton: true, timeOut: 5000, disableTimeOut: false }
      );
    }
  }
  /*********QR CODE SCANNER**************************************** */
  showScanner:boolean = false;
  ngAfterViewInit(): void {
    
  }
  public config: ScannerQRCodeConfig = {
    constraints: {
      video: {
        width: window.innerWidth
      },
    },
    // canvasStyles: [
    //   {
    //     lineWidth: 1,
    //     fillStyle: '#00950685',
    //     strokeStyle: '#00950685',
    //   },
    //   {
    //     font: '17px serif',
    //     fillStyle: '#ff0000',
    //     strokeStyle: '#ff0000',
    //   }
    // ],
  };

  public qrCodeResult: ScannerQRCodeSelectedFiles[] = [];
  public percentage = 80;
  public quality = 100;

  @ViewChild('action') action!: NgxScannerQrcodeComponent;
  public onEvent(e: ScannerQRCodeResult[], action?: any): void {
    // e && action && action.pause();
    console.log(e);
  }
  openQRCodeScanner(){
    /*this.action.isReady.subscribe((res: any) => {
      //this.handleCamera(this.action, 'start');
    });*/
    this.donorsearch = false;
    this.donoradd = false;
    this.scancertificate = true;
    this.showScannerManual = false;
    this.showScanner= true;
    //setTimeout(() => {this.handleCamera(this.action, this.showScanner)}, 500);
  }
  
  public handleCamera(action: any, fn: boolean): void {
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    }

    if (fn) {
      action['start'](playDeviceFacingBack).subscribe((r: any) => console.log(fn, r), alert);
    } else {
      action['stop']().subscribe((r: any) => console.log(fn, r), alert);
    }
  }
  public onSelects(files: any) {
    this.qrcode.loadFiles(files, this.percentage, this.quality).subscribe((res: ScannerQRCodeSelectedFiles[]) => {
      this.qrCodeResult = res;
    });
  }
  /************************************************* */
  /*******************************pledge********************** */
  pledegeDueDateChange(){
    console.log("pledge due date change===", this.pledgeDueDate, this.pledgeMinDueDate)
    if(this.pledgeDueDate<this.pledgeMinDueDate)
      this.showToast('Due date should be greater then current date', 'error');
    else if(this.pledgeDueDate<this.pledgeFollowupDate)
      this.showToast('Due date should be greater then follow up date', 'error');
  }
  followupDateChange(){
    console.log("follow up date change===", this.pledgeDueDate, this.pledgeMinDueDate)
    if(this.pledgeFollowupDate<this.pledgeMinDueDate)
      this.showToast('Follow up date should be greater then current date', 'error');
    else if(this.pledgeFollowupDate>this.pledgeDueDate)
      this.showToast('Follow up date should be less then pledge due date', 'error');
  }
  @ViewChild('closeModal') closeModal: ElementRef
  createNewPledge(stepper){
    if(!this.pledgeDueDate || !this.pledgeMinDueDate)
      this.showToast('Fill all the required fields', 'error');
    else if(this.pledgeDueDate<this.pledgeMinDueDate)
      this.showToast('Due date should be greater then current date', 'error');
    else if(this.pledgeDueDate<this.pledgeFollowupDate)
      this.showToast('Due date should be greater then follow up date', 'error');
    else if(this.pledgeFollowupDate<this.pledgeMinDueDate)
      this.showToast('Follow up date should be greater then current date', 'error');
    else if(this.pledgeFollowupDate>this.pledgeDueDate)
      this.showToast('Follow up date should be less then pledge due date', 'error');
    else {
      let obj = {
        "DonorId": this.donorid,
        "CampaignId": this.Campaigndropdown,
        "OrganizationId": this.orgid,
        "Amount": ""+this.selectedAmount,
        "DueDate": this.pledgeDueDate,
        "FollowsUpDate": this.pledgeFollowupDate,
        "OrganizationName": "abc",
        "CampaignName": this.CampaignName
      }
      this.service.savePledge(obj).subscribe((res) => {
        this.showToast('Pledge created successfully', 'success');
        this.resetfunc(stepper);
        this.closeDonationPop(stepper); 
      },
      (error) => {
        this.showToast('Internal server error!', 'error');
        this.resetfunc(stepper);
        this.closeDonationPop(stepper);
      });
    }
  }

  getDonorPledgeList(){
    let obj = {
      "DonorId": this.donorid,
      "CampaignId": this.Campaigndropdown,
      "OrganizationId": this.orgid,
    }
    
    this.service.getPledgeList(obj).subscribe((res) => {
      this.pledgeList=res.d;
      
      !this.pledgeList.length && (this.showToast('No pledge', 'error'));
      this.pledgeList.forEach(dta=>{
        dta.DueDate = dta.DueDate.split(' ')[0]
      });
    },
    (error) => {
      this.showToast('Internal server error!', 'error');
    });
  }
  payPledgeAmount(stepper){
    if (!this.selectedAmount || parseInt(this.selectedAmount) <= 0) {
        this.showToast('Please select one pledge', 'error');
      return
    }else{
      this.CloseAllDenationScreen();
      this.donateFunc();
      stepper.next();
      this.paymentMethod=true
    }
  }
  getCurrentTime() {
    var date = new Date();
    var hours: any = date.getHours();
    var minutes: any = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  closeDonationPop(stepper){
    stepper.previous();
    stepper.previous();
    this.newdonorform.reset();
    this.donoradd=true
    this.donorsearch = true;
    this.donoradd = false;
    this.closeModal.nativeElement.click();
  }

  getFullAddress(locations){
    let location = null;
    let address = '';
    locations.length && (location = locations[0]);
    
    location && (Object.keys(location).forEach(kys=>{
      location[kys] && (address += ' '+location[kys]);
    }));
    return address;
  }
}