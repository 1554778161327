import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { log } from 'console';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }


  SignIn(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/AccountSetup/SignIn", data);
  }


  ValidateSocial(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/AccountSetup/SignUpBySocials", data);
  }


  getLocations(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Locations/GetList", data);
  }


  getContacts(data: any): Observable<any> {
    return this.http.post(environment.apiUrl2 + "/Contacts/GetList", data);
  }

  getBasicDetails(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Organization/OrganizationData", data);
  }

  SaveTemplateAPI(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/SaveTemplate", data);
  }


  updateOnboardingStatus(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Organization/UpdateOnBoardingStatus", data);
  }

  updateBasicInfo(data: any): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(environment.apiUrl + "/Organization/UpdateOrganization", data, { headers });
  }

  UploadDonorsfile(data: any): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(environment.apiUrl + "/Donors/UploadDonorsExcel", data, { headers });
  }

  UploadDonorsfileCSV(data: any): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(environment.apiUrl + "/Donors/UploadDonorsExcelCSV", data, { headers });
  }

  SubmitLocation(data: any, type): Observable<any> {
    if (type == "Add") {
      return this.http.post(environment.apiUrl + "/Locations/SubmitLocation", data);
    } else {
      return this.http.post(environment.apiUrl + "/Locations/UpdateLocation", data);
    }

  }
  
  ImportDonortemplate(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/ImportDonor", data);
  }

  importData(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/ImportDonor", data);
  }

  importdataCSV(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/ImportDonorCSV", data);
  }

  saveTemplateNow(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/SaveTemplate", data);
  }

  getTemplate(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/GetTemplate", data);
  }



  GetDonorNote(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/GetDonorNote", data);
  }

  AddDonorNote(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/AddDonorNote", data);
  }

  getDonorDetailbyId(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/GetDonorById", data);
  }

  getUserDetailsByTaxID(taxid) {
    // const headers = new HttpHeaders();
    const username = 'dev_api';
    const password = 'eVK32h1bDt3n';
    const base64Credentials = btoa(username + ':' + password);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + base64Credentials
    });
    // const base64Credentials = btoa(username + ':' + password);

    return this.http.get("https://conveyservice.uatdomain.xyz/api/settings/ojcrequest.php?ojc=" + taxid, { headers });
    //return this.http.get(environment.apiUrl + "/settings/ojcrequest.php?ojc=" + taxid, { headers });
  }

  getdatabytaxidd(data: any): Observable<any> {
    const username = 'dev_api';
    const password = 'eVK32h1bDt3n';
    const base64Credentials = btoa(username + ':' + password);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + base64Credentials
    });
    //debugger;

    return this.http.get("https://api.ojcfund.org:3301/api/Organizations/0?taxID=876543213", { headers });
  }

  ConnectionList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Connections/GetList", data);
  }

  UpdateConnection(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Connections/SubmitConnection", data);
  }

  AddContact(data: any): Observable<any> {
    return this.http.post(environment.apiUrl2 + "/Contacts/SubmitContact", data);
  }




  ValidateSocialsSignup(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/AccountSetup/SignUpBySocials", data);
  }

  // AccountSetup/SignUp


  Signup(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/AccountSetup/SignUp", data);
  }


  // DataServices AccountSetup/ForgotPassword

  forgetPassword(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/AccountSetup/ForgotPassword", data);
  }


  // AccountSetup/UpdatePassword


  UpdatePassword(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/AccountSetup/UpdatePassword", data);
  }

  VerifyUser(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/AccountSetup/VerifyAccount", data);
  }


  // Campaigns/InsertCampaign

  InsertCampaign(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Campaigns/InsertCampaign", data);
  }

  // Campaigns/GetList

  GetList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Campaigns/GetList", data);
  }

  // Campaigns/UpdateCampaign

  UpdateCampaign(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Campaigns/UpdateCampaign", data);
  }

  // /Campaigns/DeleteCampaign
  DeleteCampaign(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Campaigns/DeleteCampaign", data);
  }


  // Donors/AddBasicInfo

  AddBasicInfo(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/AddBasicInfo", data);
  }


  UpdateBasicInfo(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/UpdateBasicInfo", data);
  }


  CreateGroup(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/CreateDonorGroup", data);
  }


  Getgrouplist(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/GeDonorGroupByOrganizationId", data);
  }
  Deletgroup(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/DeleteDonorGroup", data);
  }

  overhallapi(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/GetDonorOverAll", data);
  }

  updategrouplist(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/UpdateDonorGroup", data);
  }
  AddAdditionalInfo(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/AddAdditionalInfo", data);
  }

  // Donors/GetDonorList

  GetDonorList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/GetDonorList", data);
  }
  // Donors/GetDonorListWithFilters


  GetDonorListWithFilters(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donors/GetDonorListWithFilters", data);
  }
  // donation page
  adddonorfromdonation(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/AddDonationDonor", data);
  }
  donorlistfordononation(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/GetDonationDonorListWithFilters", data);
  }

  AddDonation(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/AddDonation", data);
  }

  AddCheckDonation(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/AddCheckDonation", data);
  }

  DonationsData(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/DonationsData", data);
  }

  getDonationList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/GetDonationList", data);
  }

  getTopCampaignList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/GetOrganizationTopCampaigns", data);
  }

  getTrendList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/GetOrganizationTrends", data);
  }

  getFollowAndScheduleList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/GetOrganizationDashboardDonations", data);
  }

  getAllChartData(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/GetCharts", data);
  }

  getCampaignOtherDetails(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Campaigns/GetCampaignDetailsOtherData", data);
  }
  savePledge(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/SavePledge", data);
  }
  getPledgeList(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/PledgeList", data);
  }
  setRecurringDonation(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/SaveRecurringDonation", data);
  }
  sendEmail(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/SendReceiptWithEmail", data);
  }
  printReciept(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + "/Donations/PrintReceipt", data);
  }

  
  getSettings(organizationId: number): Observable<any> {
    const url = `${environment.apiUrl2}/Settings/GetSettings?OrganizationId=${organizationId}`;
    return this.http.get<any>(url);
  }
  
  // Retrieve OrganizationId from localStorage
  getOrganizationIdFromLocalStorage(): number | null {
    const storedData = localStorage.getItem('Organization');
    if (!storedData) {
      return null; // or a default value, or throw an error
    }
    const storedObject = JSON.parse(storedData);
    // alert(storedObject.OrganizationId);
    return storedObject.OrganizationId || null;
  }
  addUser(data: any): Observable<any> {
    return this.http.post('https://ojclinx.trigma.in/ojcapiv8/api/Settings/AddUser', data);
  }
  getUser(organizationId: any, role: any, keywords: any): Observable<any> {
    let queryParams = [];
    if (organizationId) {
      queryParams.push(`organizationId=${encodeURIComponent(organizationId)}`);
    }
    if (role) {
      queryParams.push(`role=${encodeURIComponent(role)}`);
    }
    if (keywords) {
      queryParams.push(`keywords=${encodeURIComponent(keywords)}`);
    }
    const queryString = queryParams.length ? '?' + queryParams.join('&') : '';
    return this.http.get('https://ojclinx.trigma.in/ojcapiv8/api/Settings/GetAllUsers' + queryString);
  }
  editUser(data: any): Observable<any> {
    return this.http.post('https://ojclinx.trigma.in/ojcapiv8/api/Settings/EditUser', data);
  }
  newLocation(data: any): Observable<any> {
      return this.http.post(environment.apiUrl2 + "/Locations/SubmitLocation", data);
  }
  fetchlocation(data: any): Observable<any> {
    return this.http.post(environment.apiUrl2 + "/Locations/GetList", data);
  }
  updatelocation(data: any): Observable<any> {
    return this.http.post(environment.apiUrl2 + "/Locations/UpdateLocation", data);
  }
  getIsOrganizationFromLocalStorage(): boolean {
    const currentUserData = localStorage.getItem('currentUser');
    if (!currentUserData) {
      return null; 
    }
    const currentUserDataObject = JSON.parse(currentUserData);
    const IsOrganization = currentUserDataObject.IsOrganization;
    return IsOrganization;
  }
}