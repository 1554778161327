<!-- Menu header -->
<div class="navbar-header">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item mr-auto">
      <!-- App Branding -->
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="assets/images/ojc_linx_logo.png" class="main-logo m-0" alt="brand-logo" />
          <img src="assets/images/art.png" class="responsive-logo" alt="brand-logo" />
        </span>
      </a>
    </li>

    <!-- Menu Toggler -->
    <!-- <li class="nav-item nav-toggle">
      <a class="nav-link modern-nav-toggle d-none d-xl-block pr-0" (click)="toggleSidebarCollapsible()">
        <i
          [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"
        ></i>
      </a>
      <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a>
    </li> -->
  </ul>
</div>
<!--/ Menu header -->
<!--/ User logout -->
<div>
  <button class="btn acc-logoutBtn acc-logoutBtn-sml d-flex mx-auto my-2" (click)="logoutModel()">
    <img src="../../../../../assets/images/upload_logo.png" class="acc-icon">
    <div class="usertext">
      <div class="">{{userName}}</div>
      <div class="">Donation</div>
    </div>
    <img src="../../../../../assets/images/user-setting.png" class="sett-icon">
  </button>
  <div class="logout-box-sml logout-box" *ngIf="logoutFlag" (click)="logout()">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-power mr-50"><path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path><line x1="12" y1="2" x2="12" y2="12"></line></svg>
    <span>Logout</span>
  </div>
</div>
<!--/ New donation -->
<button class="btn d-flex align-items-center justify-content-center add-donate-menu-btn mx-auto my-2 big-donation-btn" data-toggle="modal" data-target="#adddonate" >
  <span><img src="../../../../../assets/images/donation-icom.png"></span>
  <span class="ml-1 donatetext">New Donation</span>
</button>


<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Main menu -->
<ng-scrollbar class="virtual-scroll" thumbClass="white-scrollbars">
<div class="main-menu-content"  (scroll)="onSidebarScroll()">
  <ul class="navigation navigation-main" layout="vertical" core-menu></ul>
</div>
</ng-scrollbar>
<!--/ Main menu -->

<!--<div *ngIf="orgname" class="bottom-org-name">
  <img src="assets/images/orgicon.png" alt="org-logo" />
  <span   class="orgname">{{orgname}}</span>
</div>-->



